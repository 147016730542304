<template>
  <div>
    <v-snackbar v-model="showModal" color="warning" right :timeout="-1" class="mb-4">
      <div>
        Software version <strong class="mx-1"> {{ latestVersion }}</strong> is available. <br />
        <!-- Please press <strong>"Ctrl + Shift + R"</strong> to update -->
      </div>

      <v-btn outlined small @click="update()" :loading="updating"> Update </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      showModal: false,
      latestVersion: null,
      updating: false,
      registration: null
    };
  },
  mounted() {
    //check for new version
    if (process.env.NODE_ENV === 'production') {
      document.addEventListener('swUpdated', this.updateAvailable, { once: true });
      setTimeout(() => this.checkForNewVersionDashboard(), 3000);
      setInterval(() => {
        this.checkForNewVersionDashboard();
      }, 1000 * 60 * 15); //each 15 min
    }
  },
  methods: {
    checkForNewVersionDashboard() {
      axios.get('/version.txt').then((res) => {
        if (res.status == 200) {
          let latestVersion;
          latestVersion = res.data;
          latestVersion = latestVersion.replace('v', '');
          latestVersion = latestVersion.split('-')[0];
          this.latestVersion = latestVersion;
          console.log('Latest version: ' + latestVersion);
          console.log('Current version: ' + this.getCurrentVersion());
          console.log('Is newer version: ' + this.isNewerVersion(this.getCurrentVersion(), latestVersion));
          if (this.isNewerVersion(this.getCurrentVersion(), latestVersion)) this.showModal = true;
        }
      });
    },

    getCurrentVersion() {
      let currentVersion = this.$store.state.config.appVersion;
      currentVersion = currentVersion.replace('v', '');
      currentVersion = currentVersion.split('-')[0];

      return currentVersion;
    },

    isNewerVersion(oldVer, newVer) {
      const oldParts = oldVer.split('.');
      const newParts = newVer.split('.');
      for (let i = 0; i < newParts.length; i++) {
        const a = ~~newParts[i]; // parse int
        const b = ~~oldParts[i]; // parse int
        if (a > b) return true;
        if (a < b) return false;
      }
      return false;
    },

    updateAvailable(event) {
      this.registration = event.detail;
    },

    update() {
      this.updating = true;

      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) {
        window.location.reload();
      }

      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  }
};
</script>

<style>
</style>