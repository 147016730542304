import Vue from 'vue';
import store from '@/store';

export function removeVuetifyCalendarError() {
  const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
  // eslint-disable-next-line no-unused-vars
  Vue.config.warnHandler = function(msg, vm, trace) {
    if (msg === ignoreWarnMessage) {
      msg = null;
      vm = null;
      trace = null;
    }
  };
}

export function setKeycloakTokens(keycloak) {
  store.commit('SET_TOKEN', keycloak.token);
  store.commit('SET_REFRESH_TOKEN', keycloak.refreshToken);
  localStorage.setItem('dashboard-dvg-kc-token', keycloak.token); //offline_token
  localStorage.setItem('dashboard-dvg-kc-refresh-token', keycloak.refreshToken); //offline_token
}

export function getLastSegmentFromPath() {
  const segment_str = window.location.pathname;
  const segment_array = segment_str.split('/');
  const last_segment = segment_array.pop();
  return last_segment;
}
