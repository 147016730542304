import store from '@/store';
import axios from 'axios';

//if keykloak reposone retrieved set default headers fo rnetwork requests
store.subscribe(mutation => {
  if (mutation.type == 'SET_TOKEN') {
    if (mutation.payload) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
      axios.defaults.headers.common['X-VERSION'] = store.state.config.appVersion;
    } else {
      axios.defaults.headers.common['Authorization'] = null;
    }
  }
});

// store.subscribeAction({
//     after (data) { console.log(data); }
// })
