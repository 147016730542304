<template>
  <v-app id="inspire">
    <MainNavigation />
    <v-content v-if="userObjectLoaded">
      <router-view class="page-wrapper" :key="$route.path" />
    </v-content>
    <SnackBar />
    <Loader />
    <RefreshDialog />
    <Footer />
    <CheckForLatestVersion />
  </v-app>
</template>

<script>
import MainNavigation from '@/components/generic/main-navigation.vue';
import SnackBar from '@/components/generic/snackbar.vue';
import Loader from '@/components/generic/main-loader.vue';
import RefreshDialog from '@/components/generic/refresh-dialog.vue';
import Footer from '@/components/generic/footer.vue';
import CheckForLatestVersion from '@/components/generic/CheckForLatestVersion.vue';

import '@/assets/scss/generic.scss';
import '@/assets/scss/transitions.scss';
import '@/assets/scss/forms.scss';
export default {
  name: 'App',

  components: {
    MainNavigation,
    SnackBar,
    Loader,
    RefreshDialog,
    CheckForLatestVersion,
    Footer
  },
  data: () => ({
    userObjectLoaded: false
  }),
  computed: {
    userObject() {
      return this.$store.state.userObject;
    }
  },
  watch: {
    userObject(n) {
      if (n) {
        setTimeout(() => {
          this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        }, 100);
        this.userObjectLoaded = true;
        this.sendToPageBasedOnGroup(n);
      }
    }
  },
  created() {
    // this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment please...' });
  },
  mounted() {
    this.handleInternetConnectivity();
  },
  methods: {
    handleInternetConnectivity() {
      window.addEventListener('offline', () => {
        let payload = {
          open: true,
          text: 'No internet connection',
          color: 'error'
        };
        this.$store.commit('SET_SNACKBAR', payload);
      });

      window.addEventListener('online', () => {
        let payload = {
          open: true,
          text: 'Back online',
          color: 'success'
        };
        this.$store.commit('SET_SNACKBAR', payload);
      });
    },

    sendToPageBasedOnGroup(role) {
      //console.log(role.roles);
      if (!role.roles.includes('admin')) {
        if (role.roles.includes('labTech')) {
          this.$router.push({ name: 'VisitorScanPage' });
        } else if (role.roles.includes('private-clinic')) {
          this.$router.push({ name: 'PrivateEntryFormPage' });
        } else if (role.roles.includes('vaccine-inventory')) {
          this.$router.push({ name: 'InventoryPage' });
        } else if (role.roles.includes('vaccine-scan')) {
          this.$router.push({ name: 'EmptyPage' });
        } else if (role.roles.includes('vaccine-registration')) {
          this.$router.push({ name: 'VaccineRegistration' });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.v-application--wrap {
  background: whitesmoke;
}
.v-input--is-disabled {
  input,
  textarea {
    color: rgba(0, 0, 0, 0.8) !important;
  }
}

.bg-light-primary {
  background: #e8f2ff !important;
}

.border-bottom-light {
  border-bottom: 1px solid #e0e0e0;
}

.bg-whitesmoke {
  background: #e8f2ff !important;
}
.bg-white {
  background: #fff !important;
}
</style>
