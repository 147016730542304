import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#366BAF',
        secondary: '#b5d7e9',
        accent: '#8c9eff',
        error: '#b71c1c',
        lightPrimary: '#e8f2ff'
      }
    }
  }
});
