<template>
  <v-dialog v-model="loading" persistent width="400">
    <v-card color="primary" dark>
      <v-card-text>
        <p class="pt-2">{{ loaderText }}</p>
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['currentMainStep']),
    loading() {
      return this.$store.state.loader.show;
    },
    loaderText() {
      return this.$store.state.loader.text;
    },
  },
};
</script>