<template>
  <v-dialog v-model="$store.state.refreshDialog" width="500" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title> Token Expired </v-card-title>

      <v-card-text class="pt-4"> Your token has expired due to inactivity. Please login again to continue. </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="reload()"> Login </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>