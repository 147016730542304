<script>
import moment from 'moment';
import axios from 'axios';
export default {
  methods: {
    hasAccessToPageMixin() {
      const rolesPage = this.$route.meta.roles;
      const rolesUser = this.$store.state.userObject.roles;
      const groupsPage = this.$route.meta.groups;
      const groupsUser = this.$store.state.userObject.groups;
      let hasAccess = false;

      //rolls check
      if (rolesUser?.length > 0 && rolesPage?.length > 0) {
        for (let i = 0; i < rolesUser.length; i++) {
          const roleUser = rolesUser[i];
          if (rolesPage.includes(roleUser)) {
            hasAccess = true;
            break;
          }
        }
      }
      // groups check
      if (groupsUser?.length > 0 && groupsPage?.length > 0) {
        for (let i = 0; i < groupsUser.length; i++) {
          const groupUser = groupsUser[i];
          if (groupsPage.includes(groupUser)) {
            hasAccess = true;
            break;
          }
        }
      }
      return hasAccess;
    },
    checkForDotEmail(email) {
      if (email && email.replace(/[^@]/g, '').length >= 2) return 'Cannot container multiple characters of @';
      else if (email && email.includes('.@')) return 'Email cannot contain a dot before the @';
      else return true;
    },
    formatDate(d) {
      return moment.utc(d, 'YYYY-MM-DDTHH:mm').local().format('DD-MMM-YYYY');
    },
    formatDateTime(d) {
      return moment.utc(d, 'YYYY-MM-DDTHH:mm').local().format('DD-MMM-YYYY / hh:mm A');
    },
    formatTime(d) {
      return moment.utc(d, 'YYYY-MM-DDTHH:mm').local().format('hh:mm A');
    },
    isToday(date) {
      const todayFormatted = moment().format('YYYY-MM-DD');
      const dateFormatted = moment.utc(date, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DD');
      if (todayFormatted == dateFormatted) return true;
      else return false;
    },
    isAfterToday(date) {
      const todayFormatted = moment().format('YYYY-MM-DD');
      const dateFormatted = moment.utc(date, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DD');
      if (moment(dateFormatted).isAfter(todayFormatted)) return true;
      else return false;
    },
    isBeforeToday(date) {
      const todayFormatted = moment().format('YYYY-MM-DD');
      const dateFormatted = moment.utc(date, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DD');
      if (moment(dateFormatted).isBefore(todayFormatted)) return true;
      else return false;
    },
    daysBeforeTillToday(date) {
      const todayFormatted = moment().format('YYYY-MM-DD');
      const dateFormatted = moment.utc(date, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DD');
      return moment(dateFormatted).diff(todayFormatted, 'days');
    },
    daysAfterTillToday(date) {
      const todayFormatted = moment().format('YYYY-MM-DD');
      const dateFormatted = moment.utc(date, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DD');
      return moment(todayFormatted).diff(dateFormatted, 'days');
    },
    isOfAge(birthDate, age) {
      if (!birthDate) return false;
      const todayFormatted = moment().format('YYYY-MM-DD');
      const birthDateFormatted = moment(birthDate, 'DD-MMMM-YYYY').format('YYYY-MM-DD');
      const calculatedAge = moment(todayFormatted).diff(birthDateFormatted, 'years');
      console.log(age, calculatedAge);
      if (calculatedAge >= age) return true;
      else return `Must be ${age} years of age`;
    },
    setLocalStorageObject(storageKey, key, value) {
      let objectLS = JSON.parse(localStorage.getItem(storageKey));
      if (objectLS) {
        objectLS[key] = value;
      } else {
        objectLS = {
          [key]: value
        };
      }
      localStorage.setItem(storageKey, JSON.stringify(objectLS));
    },
    getLocalStorageObject(storageKey, key) {
      let objectLS = JSON.parse(localStorage.getItem(storageKey));
      if (objectLS) return objectLS[key];
    },
    validateAzvNumber(number) {
      //only digits in string allowed
      if (!/^\d+$/.test(number)) return false;
      number = parseInt(number);
      if (Number.isInteger(number)) {
        number = number / 100;
        //check if can be divided by 100
        if (!Number.isInteger(number)) return false;
        const result =
          (this.getNumberFromIndex(number, 0) * 8 +
            this.getNumberFromIndex(number, 1) * 7 +
            this.getNumberFromIndex(number, 2) * 6 +
            this.getNumberFromIndex(number, 3) * 5 +
            this.getNumberFromIndex(number, 4) * 4 +
            this.getNumberFromIndex(number, 5) * 3 +
            this.getNumberFromIndex(number, 6) * 2 +
            this.getNumberFromIndex(number, 7) * 1) /
          11;
        if (Number.isInteger(result)) return true;
        else return false;
      } else {
        return false;
      }
    },
    getNumberFromIndex(number, index) {
      const numberString = number.toString();
      return parseInt(numberString[index]);
    },
    async azvNumberAlreadyRegistered(number) {
      return await axios
        .get(`${this.$store.state.baseUrlApi}/healthcheck-restful-api/user-registration/azv/${number}`)
        .then((res) => {
          if (res.status == 200 && res.data) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
          let payload = {
            open: true,
            text: 'Error checking AZV number in database',
            color: 'error'
          };
          this.$store.commit('SET_SNACKBAR', payload);
          return false;
        });
    },
    userHasRole(role) {
      if (!this.$store.state.userObject?.roles) return;
      const rolesUser = this.$store.state.userObject.roles;
      let hasRole = false;
      for (let i = 0; i < rolesUser.length; i++) {
        if (rolesUser[i] == role) {
          hasRole = true;
          break;
        }
      }
      return hasRole;
    },
    userHasGroup(group) {
      if (!this.$store.state.userObject?.groups) return;
      const groupsUser = this.$store.state.userObject.groups;
      let hasGroup = false;
      for (let i = 0; i < groupsUser.length; i++) {
        if (groupsUser[i] == group) {
          hasGroup = true;
          break;
        }
      }
      return hasGroup;
    },
    makeUid(length) {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    getItemIndex(data) {
      let tabIndex = 0;
      const clickedCaseName = this.$store.state.subjectSelectedCaseTab.caseName;
      if (clickedCaseName) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          if (element.name == clickedCaseName) {
            tabIndex = i;
            break;
          }
        }
      } else tabIndex = this.$store.state.subjectSelectedCaseTab.index;

      return tabIndex;
    },
    groupVaccineData(vaccineDataPatientCase) {
      
      let vaccineData = {};
      if (vaccineDataPatientCase?.vaccine1) {
        let fields = Object.keys(vaccineDataPatientCase);
        let filteredFields = fields.filter((x) => x.indexOf('vaccine') !== -1);

        // Group vaccine info to dynamically display dosageCards.
        for (let i = 0; i < filteredFields.length; i++) {
          let current = filteredFields[i].toLowerCase();
          const dosageNr = current.split('d')[0].replace('vaccine', '');
          if (isNaN(parseInt(dosageNr))) continue;
          if (Object.keys(vaccineData).indexOf(dosageNr) !== -1) continue; // dosage allready added.
          let vaccineDataGroup = {};
          let groupData = {};

          const key = 'vaccine' + dosageNr;
          groupData['vaccineGroupedName'] = key;
          groupData['vaccineDate'] = vaccineDataPatientCase[key];
          groupData['vaccineDose'] = vaccineDataPatientCase[key + 'Dose'];
          groupData['vaccineNr'] = dosageNr;
          const keyapt = 'appointment' + dosageNr;
          const hasappointment = fields.indexOf(keyapt) !== -1;
          if (hasappointment && vaccineDataPatientCase[keyapt]) 
            groupData['appointment'] = vaccineDataPatientCase[keyapt];

          vaccineDataGroup[dosageNr] = groupData;
          vaccineData[dosageNr] = vaccineDataGroup[dosageNr];
        }
      }
      return vaccineData;
    },
    updatePathParams(paramName, value) {
      const currentParams = this.$router.currentRoute.params;
      currentParams[paramName] = value;
      this.$router.replace({ params: currentParams });
    },
    beautifyCamelCase(item) {
      let text = item;
      let result = text.replace(/([A-Z])/g, ' $1');
      let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    }
  }
};
</script>