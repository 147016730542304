export default {
  //generic
  SET_LOGOUT_URL(state, logoutUrl) {
    state.logoutUrl = logoutUrl;
  },

  SET_USER_OBJECT(state, payload) {
    state.userObject = payload;
  },

  SET_TOKEN(state, payload) {
    state.keycloak.token = payload;
  },

  SET_REFRESH_TOKEN(state, payload) {
    state.keycloak.refreshToken = payload;
  },

  SET_KEYCLOAK_OBJECT(state, payload) {
    state.keycloak.kc = payload;
  },

  SET_SNACKBAR(state, payload) {
    state.snackbar = payload;
  },

  SET_SHOW_LOADER(state, payload) {
    state.loader.show = payload.action;
    state.loader.text = payload.text;
  },

  // SET_BACK_BUTTON(state, action) {
  //     state.showBackButton = action;
  // },

  SET_REFRESH_DIALOG(state, value) {
    state.refreshDialog = value;
  },

  SET_KC_EXPIRED_TOKEN_FN(state, value) {
    state.isTokenExpiredFn = value;
  },

  //quarantine subjects

  SET_QUARANTINED_SUBJECT_LOCAL_FILTER_TERMS(state, value) {
    state.quarantineSubjectLocalFilterTerms = value;
  },

  SET_SUBJECT_SELECTED_CASE_TAB(state, payload) {
    state.subjectSelectedCaseTab = payload;
  },

  SET_SUBJECT_CASE_TESTKIT_INFO(state, payload) {
    let testKitData = payload.map(item => {
      item.loading = false;
      return item;
    });
    state.selectedCaseTestKitData = testKitData;
  },

  SET_TESTKIT_RESULT_DOWNLOAD_LOADING(state, payload) {
    state.selectedCaseTestKitData[payload.index].loading = payload.action;
  },

  SET_SELECTED_CASE_NOTES(state, payload) {
    state.selectedCaseNotes = payload;
  },

  SET_NOTES_PAGE_SIZE(state, payload) {
    state.notesPageSize = payload;
  },

  SET_CASES(state, payload) {
    state.cases = payload;
  },

  SET_CASE_ASSIGNED_WORKERS(state, payload) {
    state.caseAssignedWorkers = payload;
  },

  SET_STATE_HISTORY_SELECTED_CASE(state, payload) {
    state.subjectSelectedCaseHistory = payload;
  },

  SET_DOCTOR_OPTIONS(state, payload) {
    let doctors = payload.map(doctor => {
      return {
        text: doctor.name,
        value: doctor.id
      };
    });
    state.doctorOptions = doctors;
  },

  //visitors
  SET_STORED_VISITORS_SUBJECTS(state, payload) {
    state.storedVistorSubjects = payload;
  },

  SET_TEST_TYPES(state, payload) {
    state.labHohData.testTypes = payload;
  },

  //visitor approval
  SET_VISITORS_FOR_APPROVAL(state, payload) {
    state.visitorApprovalPage.visitors = payload;
  },

  REMOVE_VISITOR_APPROVAL_FROM_LIST(state, id) {
    let newListVisitors = state.visitorApprovalPage.visitors.data.filter(visitor => {
      if (visitor.id != id) return visitor;
    });

    state.visitorApprovalPage.visitors.data = newListVisitors;
  },

  RESET_VISITORS_FOR_APPROVAL(state) {
    state.visitorApprovalPage.visitors = {
      data: [],
      dataSetRowCount: null
    };
  },

  //intake form
  SET_INTAKE_FORM_DATA_ITEM(state, payload) {
    state.intakeFormData.formData[payload.key] = payload.value;
  },

  SET_CURRENT_STEP_INTAKE_FORM(state, step) {
    state.intakeFormData.currentStep = step;
  },

  SET_CASE_ID_INTAKE_FORM(state, caseId) {
    state.intakeFormData.caseId = caseId;
  },

  RESET_INTAKE_FORM_DATA(state) {
    state.intakeFormData.formData = JSON.parse(JSON.stringify(state.intakeFormData.formDataPrestine));
  },

  //private entry form
  SET_PRIVATE_ENTRY_FORM_ENTRIES(state, payload) {
    if (payload && payload.length > 0) {
      if (state.privateEntryFormData.entries.length > 0) state.privateEntryFormData.entries.push(...payload);
      else state.privateEntryFormData.entries = payload;
    }
  },

  RESET_PRIVATE_ENTRY_FORM_ENTRIES(state) {
    state.privateEntryFormData.entries = [];
  },

  ADD_ITEM_PRIVATE_ENTRY_FORM_ENTRIES(state, payload) {
    payload.animateIn = true;
    state.privateEntryFormData.entries.unshift(payload);
  },

  CLEAN_UP_ANIMATION_CLASSES_PRIVATE_ENTRY_FORM(state) {
    state.privateEntryFormData.entries.forEach(element => {
      element.animateIn = false;
    });
  },

  //HOH form
  SET_HOH_ENTRY_FORM_ENTRIES(state) {
    state.privateEntryFormData.entriesOnDay = [];
  },

  // Vaccine page
  SET_VACCINE_DATA_PATIENT_CASE(state, payload) {
    state.vaccine.vaccineDataPatientCase = payload;
  },

  SET_VACCINE_LOCATIONS(state, payload) {
    state.vaccine.locations = payload;
  },

  SET_VACCINE_INVENTORY_LOCATION(state, payload) {
    state.vaccine.inventoryAtLocation = payload;
  },

  SET_VACCINE_SELECTED_LOCATION(state, payload) {
    state.vaccinePage.selectedLocation = payload;
  },

  SET_VACCINE_SELECTED_CHECKIN_LOCATION(state, payload) {
    state.vaccinePage.selectedCheckinLocation = payload;
  },

  SET_VACCINE_ENTRIES(state, payload) {
    state.vaccinePage.vaccineEntries = payload;
  },

  SET_VACCINE_SELECTED_NURSE(state, payload) {
    state.vaccinePage.selectedNurse = payload;
  },

  SET_VACCINE_SAVED_NURSES(state, payload) {
    state.vaccinePage.savedNurses = payload;
  },

  SET_VACCINE_MANUFACTURES(state, payload) {
    state.vaccinePage.manufacturers = payload;
  },

  SET_VACCINE_STORED_REGISTRATIONS(state, payload) {
    state.vaccineRegistrationPage.registrations = payload;
  },

  //vaccine generic
  SET_VACCINE_REGISTRATION_DIALOG_DATA(state, payload) {
    state.vaccine.vaccineRegistrationDialog[payload.key] = payload.data;
  }
};
