import axios from 'axios';

export const state = {
  searchedAccounts: [],
  accountSearchedQuery: null,
  accountUpdated: false,
  accountData: {},
  casesData: [],
  caseSelectedData: {},
  accountEditDelay: 600,
  newlyAddedCaseId: null
};

export const actions = {
  async searchAccountData({ rootState, commit, dispatch }, searchObj) {
    return await axios
      .post(`${rootState.baseUrlHohSearchEngine}/search`, searchObj)
      .then(res => {
        if (res.status == 200) commit('SET_SEARCHED_ACCOUNTS', res.data);
        return res.data;
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error loading account data', { root: true });
      });
  },

  async getAccountData({ rootState, commit, dispatch }, accountId) {
    return await axios
      .get(`${rootState.baseUrlHohSearchEngine}/search/${accountId}`)
      .then(res => {
        if (res.status == 200) {
          const cases = res.data.listOfCases;
          commit('SET_CASES_DATA', cases);
          delete res.data.listOfCases;
          const accountData = res.data;
          commit('SET_ACCOUNT_DATA', accountData);
          return res.data;
        } else dispatch('showError', 'Error loading account data', { root: true });
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error loading account data', { root: true });
      });
  },

  async getCaseSelectedData({ rootState, commit, dispatch }, patientCaseId) {
    return await axios
      .get(`${rootState.baseUrlApi}/healthcheck-restful-api/patientCase/${patientCaseId}`)
      .then(res => {
        if (res.status == 200 || res.status == 204) {
          //translate keys for display
          if (!res.data.patientCaseId && res.data.id) res.data.patientCaseId = res.data.id;
          if (!res.data.azvNumber && res.data.azvCardNumber) res.data.azvNumber = res.data.azvCardNumber;
          if (!res.data.birthDay && res.data.birthDate) res.data.birthDay = res.data.birthDate;
          commit('SET_CASE_SELECTED_DATA', res.data);
          return res.data;
        } else {
          dispatch('showError', 'Error loading patient case data', { root: true });
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error loading patient case data', { root: true });
      });
  },

  async updateCase({ rootState, commit, dispatch }, payload) {
    return await axios
      .put(`${rootState.baseUrlApi}/healthcheck-restful-api/patientCase/${payload.id}`, payload)
      .then(res => {
        commit('SET_CASE_SELECTED_DATA', res.data);
        commit('SET_SNACKBAR', { open: true, text: 'Case successfully edited.', color: 'success' });
        return res.data;
      })
      .catch(error => {
        dispatch('showError', 'Changes not saved, error saving case data', { root: true });
        return error;
      });
  },

  async createCase({ rootState, commit }, payload) {
    return await axios
      .post(`${rootState.baseUrlApi}/healthcheck-restful-api/patientCase`, payload)
      .then(res => {
        if (res.status == 200) {
          return res.data;
        } else {
          commit('SET_SNACKBAR', { open: true, text: 'Case not added, something went wrong.', color: 'error' });
        }
      })
      .catch(error => {
        commit('SET_SNACKBAR', { open: true, text: 'Case not added, something went wrong.', color: 'error' });
        console.error(error.response);
        return error;
      });
  }
};

export const mutations = {
  SET_SEARCHED_ACCOUNTS(state, payload) {
    state.searchedAccounts = payload;
  },
  SET_ACCOUNT_SEARCHED_QUERY(state, value) {
    state.accountSearchedQuery = value;
  },
  SET_ACCOUNT_DATA(state, payload) {
    state.accountData = payload;
  },
  SET_CASES_DATA(state, payload) {
    state.casesData = payload;
  },
  SET_CASE_SELECTED_DATA(state, payload) {
    state.caseSelectedData = payload;
  },
  SET_ACCOUNT_UPDATED(state, payload) {
    state.accountUpdated = payload;
  },
  SET_NEWLY_ADDED_CASE_ID(state, payload) {
    state.newlyAddedCaseId = payload;
  }
};

export const getters = {
  caseSelectedData: state => {
    return state.caseSelectedData;
  },
  accountData: state => {
    return state.accountData;
  },
  casesData: state => {
    return state.casesData;
  },
  accountUpdated: state => {
    return state.accountUpdated;
  },
  accountEditDelay: state => {
    return state.accountEditDelay;
  },
  newlyAddedCaseId: state => {
    return state.newlyAddedCaseId;
  }
};
