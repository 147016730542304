import Vue from 'vue';
import VueRouter from 'vue-router';
import AccountSearchPage from '../views/AccountSearchPage/AccountSearchPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'AccountSearchPage',
    component: AccountSearchPage,
    meta: {
      roles: ['admin'],
      groups: ['DVG-AAA']
    }
  },
  {
    path: '/cases',
    name: 'CasesPage',
    component: () => import('../views/CasesPage/CasesPage.vue'),
    meta: {
      roles: ['admin']
    }
  },
  {
    path: '/account/:accountId?/:caseId?',
    name: 'AccountPage',
    component: () => import('../views/AccountPage/AccountPage.vue'),
    meta: {
      roles: ['admin'],
      groups: ['DVG-AAA']
    }
  },
  // {
  //   path: '/account/:accountId?/:tabIndex?',
  //   name: 'AccountPage',
  //   component: () => import('../views/AccountPage/AccountPage.vue'),
  //   meta: {
  //     roles: ['admin']
  //   }
  // },
  {
    path: '/visitor-scan/:caseId?',
    name: 'VisitorScanPage',
    component: () => import('../views/VisitorScanPage/VisitorScanPage.vue'),
    meta: {
      roles: ['admin', 'labTech', 'vaccine-registration'],
      groups: ['DVG-AAA']
    }
  },
  {
    path: '/visitor-approval',
    name: 'visitorApprovalPage',
    component: () => import('../views/VisitorApprovalPage/VisitorApprovalPage.vue'),
    meta: {
      roles: ['admin'],
      groups: ['DVG-AAA']
    }
  },
  {
    path: '/intake-form/:caseId',
    name: 'visitorApprovalPageByCase',
    component: () => import('../views/IntakeFormPage/IntakeFormPage.vue'),
    meta: {
      roles: ['admin']
    }
  },
  {
    path: '/private-entry-form',
    name: 'PrivateEntryFormPage',
    component: () => import('../views/PrivateEntryFormPage/PrivateEntryFormPage.vue'),
    meta: {
      roles: ['admin', 'private-clinic']
    }
  },
  {
    path: '/hoh-entry-form',
    name: 'HohEntryFormPage',
    component: () => import('../views/HohEntryFormPage/HohEntryFormPage.vue'),
    meta: {
      roles: ['admin']
    }
  },
  {
    path: '/hoh-entry-form/:selectedDate',
    name: 'HohEntryFormEntriesPage',
    component: () => import('../views/HohEntryFormPage/HohEntryFormEntriesPage/HohEntryFormEntriesPage.vue'),
    meta: {
      roles: ['admin', 'labTech']
    }
  },
  {
    path: '/vaccine-inventory',
    name: 'InventoryPage',
    component: () => import('../views/VaccineViews/VaccineInventoryPage/VaccineInventoryPage.vue'),
    meta: {
      roles: ['admin', 'vaccine-inventory']
    }
  },
  {
    path: '/vaccine-scan/:caseId?',
    name: 'VaccineCheckoutPagePage',
    component: () => import('../views/VaccineViews/VaccineCheckoutPage/VaccineCheckoutPage.vue'),
    meta: {
      roles: ['admin', 'vaccine-scan']
    }
  },
  {
    path: '/vaccine-checkin-scan/:caseId?',
    name: 'VaccineCheckingPage',
    component: () => import('../views/VaccineViews/VaccineCheckinScanPage/VaccineCheckinScanPage.vue'),
    meta: {
      roles: ['admin', 'vaccine-scan']
    }
  },
  {
    path: '/vaccine-registration',
    name: 'VaccineRegistration',
    component: () => import('../views//VaccineViews/VaccineRegistrationPage/VaccineRegistrationPage.vue'),
    meta: {
      roles: ['vaccine-registration', 'admin']
    }
  },
  {
    path: '/welcome',
    name: 'EmptyPage',
    component: () => import('../views/EmptyPage/EmptyPage.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
