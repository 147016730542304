
export class intakeFormDataKeys {
    constructor() {
        return {
            district: null,
            sex: null,
            birthDate: null,
            age: null,
            ageCat1: null,
            ageCat2: null,
            residentOrTourist: null,
            occupation: null,
            onsetDate: null,
            epiWeek: null,
            fever38Higher: null,
            maxTemp: null,	
            cough: null,
            shortnessBreath: null,
            otherPresence: null,
            fatigue: null,
            soreThroat: null,	
            runnyNose: null,
            vomiting: null,
            nausea: null,
            diarrhea: null,
            headache: null,
            myalgia: null,
            anosmia: null,
            otherSymptoms: null,
            presenceChronicHealthConditions: null,
            cvd: null,
            diabetes: null,
            chronicRespDisease: null,	
            highBloodPressure: null,
            cancer: null,
            compormisedImmune: null,
            otherHealthCondition: null,
            otherSpecify: null,
            hospitalized: null,
            dateAdmin: null,
            dateDischarg: null,
            dateChangeHospital: null,	
            levelOfCare: null,
            ventilation: null,
            levelOfCare2: null,	
            comorbidity:null,
            totDaysHoh: null,
            outcomeHospital: null,
            statusCase: null,
            dateIsolRelease: null,
            totDaysIso: null,
            travelHistory: null,
            country1: null,	
            contactConfirmedCase14Days: null,
            links: null,
            primarySecundaryNumber: null,	
            nameLab: null,
            typeSwabTaken: null,
            dateSwab1: null,
            origin: null,	
            fever38Higher2: null,
            maxTemp3: null,
            cough4: null,
            shortnessBreath5: null,
            otherPresence6: null,	
            fatigue7: null,
            soreThroat8: null,	
            runnyNose9: null,
            vomiting10: null,
            nausea11: null,	
            diarrhea12: null,
            headache13: null,
            myalgia14: null,
            anosmia15: null,
            otherSymptoms16: null,	
            fever38Higher3: null,	
            maxTemp4: null,
            cough5: null,
            shortnessBreath6: null,	
            otherPresence7: null,	
            fatigue8: null,
            soreThroat9: null,
            runnyNose10: null,
            vomiting11: null,	
            nausea12: null,
            diarrhea13: null,	
            headache14: null,	
            myalgia15: null,
            anosmia16: null,
            otherSymptoms17: null,
            socialActivityComment: null
        }
    }
  }

