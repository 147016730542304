export default {
  userObject: state => {
    return state.userObject;
  },
  selectedCaseNotes: state => {
    return state.selectedCaseNotes;
  },
  intakeFormData: state => {
    return state.intakeFormData.formData;
  },
  testTypes: state => {
    return state.labHohData.testTypes;
  },
  vaccineRegirationDialogData: state => {
    return state.vaccine.vaccineRegistrationDialog;
  }
};
