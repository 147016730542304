<template>
  <v-snackbar
    class="snackbar-custom"
    v-model="$store.state.snackbar.open"
    :color="$store.state.snackbar.color"
    :top="position == 'top' ? true : false"
    :bottom="position == 'bottom' ? true : false"
    :left="position == 'left' ? true : false"
    :right="position == 'right' ? true : false"
    :class="this.$vuetify.breakpoint.smAndUp ? 'lg' : ''"
    :timeout="timeout"
  >
    {{ $store.state.snackbar.text }}

    <v-btn v-if="stateSB.customButton" outlined color="white pa-2 me-0" @click="stateSB.customButton()"> View </v-btn>
    <v-btn color="white pa-2 me-0" fab text x-small @click="closeSnackbar()">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    position() {
      if (this.$store.state.snackbar.position) return this.$store.state.snackbar.position;
      else return 'top';
    },
    timeout() {
      const timeout = this.$store.state.snackbar.timeout;
      if (timeout || timeout === 0) return timeout;
      else return 4000;
    },
    stateSB() {
      return this.$store.state.snackbar;
    }
  },
  methods: {
    closeSnackbar() {
      this.$store.commit('SET_SNACKBAR', { open: false, text: null, color: null });
    }
  }
};
</script>

<style lang="scss">
.snackbar-custom.lg {
  .v-snack__content {
    font-size: 1.5rem !important;
    font-weight: 500;
    line-height: 1.8rem;
    padding: 15px !important;
  }
}
</style>