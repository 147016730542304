<template>
  <div class="nav-wrapper">
    <v-navigation-drawer v-model="drawer" app :disable-resize-watcher="true" :mini-variant="miniMenu" permanent>
      <v-list dense>
        <template class="home-group" v-if="userHasRole('admin') || userHasGroup('DVG-AAA')">
          <v-list-item link to="/">
            <v-list-item-action v-tooltip="'Search'">
              <v-icon>mdi-account-search</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Search</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/cases">
            <v-list-item-action v-tooltip="'Cases'">
              <v-icon>mdi-folder</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Cases</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template class="visitor-group" v-if="!hasVaccineRoles || userHasRole('admin')">
          <v-list-item v-if="!miniMenu">
            <v-list-item-subtitle> Visitors </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="mx-4"></v-divider>
          <v-list-item link to="/visitor-approval">
            <v-list-item-action v-tooltip="'Visitor Approval'">
              <v-icon>mdi-airplane</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Visitor Approval</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/visitor-scan">
            <v-list-item-action v-tooltip="'Visitor Scan'">
              <v-icon>mdi-qrcode-scan</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Visitor Scan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template class="vaccine-group" v-if="hasVaccineRoles">
          <v-list-item v-if="!miniMenu">
            <v-list-item-subtitle> Vaccine </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="mx-4"></v-divider>
          <v-list-item link to="/vaccine-registration" v-if="userHasRole('vaccine-registration')">
            <v-list-item-action v-tooltip="'Visitor Registration'">
              <v-icon>mdi-needle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Vaccine Registration</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/vaccine-inventory" v-if="userHasRole('vaccine-inventory')">
            <v-list-item-action v-tooltip="'Vaccine Inventory'">
              <v-icon>mdi-package-variant-closed</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Vaccine Inventory</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/vaccine-checkin-scan" v-if="userHasRole('vaccine-scan')">
            <v-list-item-action v-tooltip="'Vaccine Check In'">
              <v-icon>mdi-map-marker-plus</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Vaccine Check In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/vaccine-scan" v-if="userHasRole('vaccine-scan')">
            <v-list-item-action v-tooltip="'Vaccine Check Out'">
              <v-icon>mdi-map-marker-minus</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Vaccine Check Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template class="hospital-group" v-if="!hasVaccineRoles || userHasRole('admin')">
          <v-list-item v-if="!miniMenu">
            <v-list-item-subtitle> Entry Forms </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="mx-4"></v-divider>
          <v-list-item link to="/private-entry-form">
            <v-list-item-action v-tooltip="'Private Entry Form'">
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Private Entry Form</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/hoh-entry-form">
            <v-list-item-action v-tooltip="'HOH Entry Form'">
              <v-icon>mdi-hospital-building</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>HOH Entry Form</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="miniMenu = !miniMenu" />
      <img link class="logo" :src="require('@/assets/images/generic/logo-dvg.svg')" alt="" srcset="" />
      <v-toolbar-title class="hidden-xs-only font-weight-medium">Aruba Health Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu right bottom offset-y v-if="$store.state.userObject">
        <template v-slot:activator="{ on }">
          <div class="user-dropdown-menu">
            <v-btn v-on="on" class="me-0" icon elevation="0">
              <v-icon large>mdi-account-circle</v-icon>
            </v-btn>
          </div>
        </template>

        <v-list class="px-4">
          <v-list-item>
            <v-list-item-content>
              <v-icon large slot="start">mdi-account-circle</v-icon>
              <v-list-item-title class="subtitle-1 text-center">
                <span v-if="$store.state.userObject.name">
                  {{ $store.state.userObject.name }}
                </span>
                <span v-else>
                  {{ $store.state.userObject.email }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class=""></v-divider>
          <v-list-item @click="logout()">
            <v-list-item-title>
              <v-icon left>mdi-logout-variant</v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
export default {
  mixins: [SharedFunctions],
  computed: {
    hasVaccineRoles() {
      if (!this.$store.state.userObject) return false;
      const roles = this.$store.state.userObject.roles;
      if (roles.includes('vaccine-scan') || roles.includes('vaccine-registration') || roles.includes('vaccine-inventory')) return true;
      else return false;
    },
    showVisitorScan() {
      if (!this.$store.state.userObject) return false;
      if (this.userHasRole('admin') && !this.userHasRole('vaccine-scan') && !this.userHasRole('vaccine-inventory')) return true;
      else return false;
    },
    screenSmallerThanMedium() {
      return this.$vuetify.breakpoint.mdAndDown;
    }
  },
  watch: {
    screenSmallerThanMedium: {
      immediate: true,
      handler(n, o) {
        if (n != o) this.miniMenu = n;
      }
    }
  },
  data() {
    return {
      drawer: true,
      miniMenu: false
    };
  },
  mounted() {
    //this.drawer = this.$vuetify.breakpoint.mdAndDown ? false : true
    if(process.env.NODE_ENV === "development" && this.$route.name == null)
      console.log("\n\nTHE URL YOU NAVIGATED TO DOES NOT EXIST.\n\n");
      
    setTimeout(() => {
      if (this.$route.name === 'EmptyPage') this.drawer = true;
    }, 200);
  },
  methods: {
    logout() {
      localStorage.removeItem('dashboard-dvg-kc-token'); //offline_token
      localStorage.removeItem('dashboard-dvg-kc-refresh-token'); //offline_token
      this.$store.state.keycloak.kc.logout();
    }
    // hasRole(role) {
    //   if (!this.$store.state.userObject) return false;
    //   const roles = this.$store.state.userObject.roles;
    //   if (roles.includes(role)) return true;
    //   else return false;
    // },
  }
};
</script>

<style lang="scss">
.nav-wrapper {
  .v-toolbar__title {
    overflow: visible;
  }
  .logo {
    max-width: 100px;
    justify-self: center;
    //margin-top: 8px;
    // &:hover {
    //   cursor: pointer;
    // }
  }
  .title-custom {
    margin-left: 15px;
    padding-left: 10px;
    font-size: 16px !important;
    border-left: 2px solid #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 6px 10px;
  }
}
</style>
