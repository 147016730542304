<template>
  <v-card class="mx-auto subject-card" style="border-radius: 10px" :disabled="disabled">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-2 grey--text" v-if="subject.enabled"><v-chip color="success" x-small outlined>STATUS: ENABLED</v-chip></div>
        <div class="overline mb-2 grey--text" v-else><v-chip color="error" x-small outlined>STATUS: DISABLED</v-chip></div>
        <v-list-item-title class="headline mb-1 primary--text text--darken-1">{{ subject.name }}</v-list-item-title>
        <v-list-item-subtitle class="small-sub-title">Username</v-list-item-subtitle>
        <v-list-item-subtitle>{{ subject.username }}</v-list-item-subtitle>
        <v-list-item-subtitle class="small-sub-title">Date Created</v-list-item-subtitle>
        <v-list-item-subtitle>{{ subject.created }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar size="60" color="grey" class="pa-2 lighten-3">
        <v-icon class="lighten-4" color="grey" large> mdi-account</v-icon>
      </v-list-item-avatar>
    </v-list-item>

    <v-divider class="mx-4"></v-divider>

    <div v-if="subject.cases && subject.cases.length > 0">
      <v-card-title class="subtitle-1 primary--text text--darken-2 p-0 pt-2 pb-2"
        >Subject Cases: <v-chip small class="ms-2 font-weight-bold px-2" color="primary" v-if="cases.length > 0">{{ cases.length }}</v-chip>
      </v-card-title>
      <!-- <v-card-text class="pb-0">
        <v-chip small color="primary" outlined class="ma-2 full-width" v-for="(caseItem, n) in cases" @click="onClickCase(n)" :key="'case-' + n">
          <v-icon left small>{{ caseItem.icon }}</v-icon>
          <div v-for="(part, n) in caseItem.tagParts" :key="'case-part' + n" :class="`case-part ${part.type}`">{{ part.text }}</div>
        </v-chip>
      </v-card-text> -->

      <v-simple-table class="cases-table mx-4 pb-0" dense>
        <tbody>
          <tr v-for="(caseItem, i) in cases" :key="'case-' + i" @click="onClickCase(caseItem.id)">
            <td>
              <div v-for="(part, n) in caseItem.tag" :key="'case-part' + n" :class="`case-part ${part.type} py-2`">
                <v-icon left color="primary">{{ caseItem.icon }}</v-icon> {{ part.text }}
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="me-2 mb-2 mt-1" color="primary" elevation="0" @click="onClickView"> view <v-icon right>mdi-arrow-right</v-icon> </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { highlight } from '@/utils/highlight';

export default {
  props: {
    subject: {
      type: Object,
      required: true
    },
    highlightTerms: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    cases() {
      return (this.subject?.cases || []).map(({ relation, tag, patientCaseId }) => {
        return {
          icon: relation == 'child' ? 'mdi-folder-network' : 'mdi-folder',
          tag: highlight(tag, this.highlightTerms),
          id: patientCaseId
        };
      });
    }
  },

  methods: {
    onClickCase(patientCaseId) {
      console.log(patientCaseId);
      this.$emit('click-case', patientCaseId);
    },

    onClickView() {
      this.$emit('click-view');
    }
  }
};
</script>

<style lang="scss">
.subject-card {
  .small-sub-title {
    font-size: 12px !important;
    color: #366baf !important;
    font-weight: 600;
    margin-top: 5px;
  }
  .highlight {
    background-color: yellow;
  }
  .case-part {
    white-space: pre-wrap;
  }
  .cases-table {
    max-height: 200px;
    overflow: auto;
    background: #f9f9f9 !important;
    border: 1px solid lightgrey;
    padding-bottom: 0 !important;
    tr {
      &:hover {
        cursor: pointer;
      }
      td {
        color: grey;
        font-size: 12px;
      }
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>