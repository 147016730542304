export class vaccineRegistrationDataKeys {
  constructor() {
    return {
      date: null,
      nurseName: null,
      selectedLocation: null,
      inventoryIdVaccine: null
    };
  }
}
