<template>
  <v-container fluid v-if="hasAccessToPage">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="10">
        <SearchedAccountsCards />
      </v-col>
    </v-row>
  </v-container>
  <NoAccessMessage v-else-if="hasAccessToPage == false" />
</template>

<script>
import SearchedAccountsCards from '@/views/AccountSearchPage/components/searched-account-cards.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import NoAccessMessage from '@/components/generic/no-access-message.vue';

export default {
  mixins: [SharedFunctions],
  components: {
    SearchedAccountsCards,
    NoAccessMessage
  },
  data() {
    return {
      hasAccessToPage: null
    };
  },
  created() {
    setTimeout(() => (this.hasAccessToPage = this.hasAccessToPageMixin()), 100);
  }
};
</script>