<template>
  <div>
    <transition name="slide-bottom">
      <v-alert v-model="userAddedAlert" v-if="userAddedAlert" dismissible color="primary" border="left" elevation="2" colored-border>
        <v-row align="center" v-if="userAddedData">
          <v-col class="grow">
            <h3 class="primary--text">User Added</h3>
            <div>First Name: {{ userAddedData.firstName }}</div>
            <div>Last Name: {{ userAddedData.lastName }}</div>
            <div>User Name: {{ userAddedData.username }}</div>
          </v-col>
          <v-col class="shrink">
            <v-btn class="grey--text text--darken-2 elevation-0" @click="searchCreatedUser(userAddedData.username)"
              ><v-icon left>mdi-magnify</v-icon>Search Account</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </transition>

    <v-card class="main-card">
      <v-card-title style="display: block" class="bg-light-primary border-bottom-light">
        <v-row>
          <v-col cols="12" sm="8" offset-sm="2" :class="searchedAccounts.length > 0 && !noResults ? 'my-2' : 'py-10'" class="header-animate">
            <v-form v-model="formValid" v-on:submit.prevent="searchAccount(accountSearchedQuery)" ref="search-account-form">
              <v-text-field
                outlined
                rounded
                :dense="searchedAccounts.length > 0 && !noResults"
                background-color="#ffffffc7"
                v-model="accountSearchedQuery"
                append-icon="mdi-magnify"
                @click:append="searchAccount(accountSearchedQuery)"
                label="Search accounts"
                single-line
                hide-details
                clearable
                :disabled="loadingSubjects"
                @click:clear="clearSubjectsClicked()"
              ></v-text-field>
              <div elevation="0" class="caption mt-4" v-if="!displayResult">
                <div>Search examples:</div>
                <div>full name, eg. "John Doe"</div>
                <div>email, eg. "john.doe@dvg.org"</div>
                <div>case, eg. "case 1234"</div>
                <div>AZV number, eg. "1071234567"</div>
                <div>case ID (^^ case id ^), eg, "^^bf191069-310d-4bce-bb7a-f34143c39739^"</div>
              </div>
            </v-form>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn color="primary" absolute right fab @click.stop="openCreateUserDialog()" class="fab-create-user">
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
      </v-card-title>

      <!-- LOADER -->
      <v-progress-linear v-if="loadingSubjects" :size="30" color="primary" class indeterminate></v-progress-linear>

      <!-- search filter -->
      <!-- <v-row class="pa-4" v-if="displayResult">
        <v-col cols="12" sm="6" offset-sm="3">
          <v-text-field
            v-model="localFilter"
            :disabled="loadingSubjects"
            @input="onChangeLocalFilter"
            placeholder="Filter by username, first name/last name, case number"
            append-icon="mdi-filter-variant"
            ref="local-filter-input"
            clearable
            autofocus
          ></v-text-field>
        </v-col>
      </v-row> -->

      <!-- results -->
      <v-row class="pa-4" v-if="searchedAccountsMappedLocal.length > 0">
        <v-col cols="12" sm="6" md="6" lg="4" v-for="(subject, i) in searchedAccountsMappedLocal" :key="'subject-' + i">
          <AccountCard
            :subject="subject"
            :disabled="loadingSubjects"
            :highlightTerms="localFilterTerms"
            @click-view="viewCasePage(i)"
            @click-case="(patientCaseId) => viewCasePage(i, patientCaseId)"
          />
        </v-col>
      </v-row>
    </v-card>

    <transition name="slide-bottom">
      <v-alert v-model="noResults" class="mt-12" dismissable type="info" dismissible>No Resutls Found</v-alert>
    </transition>

    <!-- DIALOG USER -->
    <v-dialog v-model="dialogUser" width="800" scrollable>
      <v-card>
        <v-card-title class="headline primary white--text" primary-title> Create New Subject </v-card-title>
        <v-card-text>
          <AccountForm :newUser="true" v-if="dialogUser" ref="userFormComponent" :formError="userFormError" />
        </v-card-text>

        <v-divider></v-divider>
        <v-alert type="error" v-if="dialogError" dense dismissible> Please fill in all required fields </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogUser = false"> Cancel </v-btn>
          <v-btn color="primary" @click="addNewUser()"> Create </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//TODO: fix filter
import { mapGetters } from 'vuex';
import moment from 'moment';
import AccountForm from '@/components/account-and-case-components/account-form.vue';
import AccountCard from '@/views/AccountSearchPage/components/account-card.vue';

export default {
  components: {
    AccountForm,
    AccountCard
  },
  data() {
    return {
      userAddedAlert: false,
      userAddedData: null,
      noResults: false,
      formValid: null,
      userFormError: false,
      dialogUser: false,
      dialogError: false,
      loadingSubjects: false,
      localFilter: '',
      quarantineTypeFilter: ['From Abroad', 'Locally Transmitted'],
      localFilterChangeTimeout: null,
      search: '',
      headers: [
        { text: 'Type', align: 'start', sortable: false, value: 'thumb' },
        { text: 'Full Name', value: 'name' },
        { text: 'Username', value: 'username' },
        { text: 'Date Created', value: 'created' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    };
  },
  created() {},
  mounted() {
    if ((this.$route.query.q && this.casesData.length == 0) || this.accountUpdated) {
      const q = this.$route.query.q;
      this.$store.commit('SET_ACCOUNT_SEARCHED_QUERY', q);
      this.searchAccount(q);
      this.$store.commit('SET_ACCOUNT_UPDATED', false);
    }
    this.restoreLocalFilter();
  },
  computed: {
    ...mapGetters(['casesData', 'accountUpdated']),
    searchedAccounts() {
      return this.$store.state.accountData.searchedAccounts;
    },
    searchedAccountsMapped() {
      return this.searchedAccounts.map((subject, index) => {
        const name = subject.firstName ? subject.firstName + ' ' + subject.lastName : 'No name registration';
        return {
          index: index,
          enabled: subject.keycloakState === 'true' ? true : false,
          username: subject.keycloakEmail,
          thumb: 'account',
          name,
          created: moment(subject.createdTimestamp).format('DD-MMM-YYYY'),
          cases: subject.tags?.length > 0 ? subject.tags : [],
          searchString: `${name}${subject.username}${subject.tags.map((t) => t.tagName)}`.toLowerCase()
        };
      });
    },
    searchedAccountsMappedLocal() {
      return this.searchedAccountsMapped.filter((subject) => this.localFilterTerms.every((part) => subject.searchString.includes(part)));
    },
    userObject() {
      return this.$store.state.userObject;
    },
    accountSearchedQuery: {
      get() {
        return this.$store.state.accountData.accountSearchedQuery;
      },
      set(value) {
        if (value) value = value.trim();
        this.$store.commit('SET_ACCOUNT_SEARCHED_QUERY', value);
      }
    },
    localFilterTerms: {
      get() {
        return this.$store.state.quarantineSubjectLocalFilterTerms;
      },
      set(value) {
        this.$store.commit('SET_QUARANTINED_SUBJECT_LOCAL_FILTER_TERMS', value);
      }
    },
    displayResult() {
      return this.searchedAccounts.length > 0 && !this.noResults;
    }
  },
  methods: {
    viewCasePage(accountIndex, patientCaseId) {
      if (!patientCaseId) {
        patientCaseId = this.searchedAccountsMappedLocal[accountIndex]?.cases[0]?.patientCaseId;
      }
      window.scrollTo(0, 0);
      this.$store.commit('SET_ACCOUNT_DATA', {});
      this.$store.commit('SET_CASES_DATA', []);
      this.$store.commit('SET_CASE_SELECTED_DATA', []);
      this.$router.push(`/account/${this.searchedAccounts[accountIndex].keycloakId}/${patientCaseId}`);
    },

    searchAccount(search) {
      if (!search) return;
      this.$router.push({ path: '/', query: { q: search } }).catch(() => 'router catch');

      //Clear the local filter on new searches.
      this.clearLocalFilter();

      this.noResults = false;
      search = search.replace(/\s{2,}/g, ' ');
      if (this.$refs['search-account-form'].validate()) {
        this.loadingSubjects = true;

        this.$store
          .dispatch('searchAccountData', { search: search })
          .then((data) => {
            this.loadingSubjects = false;
            if (data && data.length > 0) {
              this.noResults = false;
              this.focusLocalFilter();
            } else if (data && data.length == 0) this.noResults = true;
          })
          .catch(() => {
            this.loadingSubjects = false;
            this.noResults = false;
          })
          .finally(() => (this.loadingSubjects = false));
      }
    },

    clearSubjectsClicked() {
      this.noResults = false;
      this.$router.push({ path: '/' }).catch();
      this.$store.commit('SET_SEARCHED_ACCOUNTS', []);
      //Also clear the local filters on clearing the subjects.
      this.clearLocalFilter();
    },

    openCreateUserDialog() {
      this.dialogUser = true;
    },

    addNewUser() {
      this.userFormError = false;
      let formData = this.$refs.userFormComponent.formData;

      if (this.$refs.userFormComponent.$refs['form-account'].validate()) {
        this.$store.dispatch('createUser', formData).then((data) => {
          if (data != false) {
            this.$store.commit('SET_ACCOUNT_SEARCHED_QUERY', null);
            this.dialogUser = false;

            data.firstName = formData.firstName;
            data.lastName = formData.lastName;
            data.username = data.userId;
            data.id = data.keycloakId;

            this.userAddedData = data;
            setTimeout(() => {
              this.userAddedAlert = true;
            }, 400);
            //this.$store.commit('SET_QUARANTINED_SUBJECTS', [data]);
          }
        });
      } else {
        this.userFormError = true;
      }
    },

    searchCreatedUser(query) {
      this.userAddedAlert = false;
      this.$store.commit('SET_ACCOUNT_SEARCHED_QUERY', query);
      this.searchAccount(query);
    },

    onChangeLocalFilter(change) {
      this.localFilterChangeTimeout && clearTimeout(this.localFilterChangeTimeout);
      if (change) {
        //Set a delay on local filters for performance.
        this.localFilterChangeTimeout = setTimeout(() => {
          this.localFilterTerms = (change || '').trim().toLowerCase().replace(/s{2,}/gi, ' ').split(' ');
          //Clear the timeout reference.
          this.localFilterChangeTimeout = null;
        }, 300);
      } else {
        this.localFilterTerms = [];
      }
    },

    clearLocalFilter() {
      //Clear the local filter and input.
      this.localFilter = '';
      this.localFilterTerms = [];
    },

    focusLocalFilter() {
      setTimeout(() => {
        const input = this.$refs['local-filter-input'];
        input?.focus();
      }, 100);
    },

    restoreLocalFilter() {
      this.localFilter = this.localFilterTerms.join(' ');
    }
  }
};
</script>

<style lang="scss">
.fab-create-user {
  margin-top: -14px !important;
  margin-right: 10px;
}

.main-card {
  .header-animate {
    transition: padding 150ms ease-in !important;
  }
}
</style>
