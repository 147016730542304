export const highlight = (text = '', searchTerms = []) => {
  const searchableText = text.toLowerCase();

  const highlightRanges = [];
  //Find the highlight ranges.
  searchTerms?.forEach(term => {
    const index = searchableText.indexOf(term);
    if (index !== -1) {
      const startIndex = index;
      const endIndex = index + term.length;
      //Check if ranges do not overlap.
      const overlaps = highlightRanges.filter(r => (startIndex >= r[0] && startIndex <= r[1]) || (endIndex >= r[0] && endIndex <= r[1]));
      if (overlaps.length > 0) {
        //Just adjust the overlap.
        overlaps.forEach(overlap => {
          overlap[0] = Math.min(startIndex, overlap[0]);
          overlap[1] = Math.min(endIndex, overlap[1]);
        });
      } else {
        //Push the new freestanding range.
        highlightRanges.push([index, index + term.length]); //Check if not length - 1
      }
    }
  });
  //Sort the highlight ranges.
  highlightRanges.sort((r1, r2) => r1[0] - r2[0]);

  //Construct the parts.
  let currentIndexInText = 0;
  const textParts = [];
  highlightRanges.forEach(range => {
    if (range[0] > currentIndexInText) {
      //Add normal text till the highlighed range.
      textParts.push({ type: 'normal', text: text.slice(currentIndexInText, range[0]) });
    }
    textParts.push({ type: 'highlight', text: text.slice(range[0], range[1]) });
    currentIndexInText = range[1];
  });
  if (currentIndexInText != text.length) {
    //Add the final part as normal text.
    textParts.push({ type: 'normal', text: text.slice(currentIndexInText, text.length) });
  }
  return textParts;
};
