<template>
  <v-footer color="primary py-0" padless>
    <v-row justify="center" no-gutters>
      <v-col class="primary py-0 pe-2 text-right white--text" cols="12" style="font-size: 12px">
        {{ $store.state.config.appVersion }}
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {};
</script>

<style>
</style>