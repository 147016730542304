<template>
  <v-card class="elevation-0">
    <v-form v-model="formValid" ref="form-account">
      <v-alert type="error" v-if="formError" dense dismissible class="mt-4"> Please fill in all required fields </v-alert>
      <v-container style="padding-top: 20px">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="formData.firstName"
              :rules="[(v) => !!v || 'This field is required']"
              label="First name"
              required
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="formData.lastName"
              :rules="[(v) => !!v || 'This field is required']"
              label="Last name"
              required
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="newUser">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="formData.phoneNumber"
              :rules="rulesPhone"
              type="tel"
              label="Telephone"
              required
              @input="setEmailRules()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="newUser">
          <v-col cols="12" md="6">
            <v-text-field v-model="formData.email" :rules="rulesEmail" type="email" label="Email" required @change="setPhoneRules()"></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="newUser">
          <v-col cols="12" md="6">
            <v-checkbox
              @change="setSmsFormCreation($event)"
              v-model="smsOnCreationCheckBox"
              label="Notify new subject"
              hint="Send SMS or Email to new subject"
              :persistent-hint="true"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="!newUser">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="formData.username"
              :rules="[(v) => !!v || 'This field is required']"
              type="text"
              label="Username"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import { mapGetters } from 'vuex';

export default {
  mixins: [SharedFunctions],
  props: ['newUser', 'formError', 'editUser'],
  data() {
    return {
      showEditAlert: false,
      formValid: null,
      formData: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        idNumber: '00.00.00.00',
        email: null,
        smsOnCreation: 'yes',
        username: null
      },
      smsOnCreationCheckBox: true,
      rulesEmail: null,
      rulesPhone: null
    };
  },
  computed: {
    ...mapGetters(['accountData'])
  },
  created() {
    if (this.newUser) {
      this.setEmailRules();
      this.setPhoneRules();
    }

    if (this.editUser) {
      this.prefillForm();
    }
  },
  methods: {
    updateValue(value, keyName) {
      if (this.formData[keyName] === undefined && keyName != 'callDateTime') {
        this.$set(this.formData, keyName, null);
      }
      if (keyName == 'callDateTime') {
        this.timeFromPicker = value;
      } else this.formData[keyName] = value;
    },

    setPhoneRules() {
      if (!this.formData.email) this.rulesPhone = [(v) => !!v || 'This field is required'];
      else this.rulesPhone = [];
    },

    setEmailRules() {
      if (!this.formData.phoneNumber) {
        this.rulesEmail = [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid', (v) => this.checkForDotEmail(v)];
      } else {
        this.rulesEmail = [];
      }
    },

    prefillForm() {
      this.$set(this.formData, 'firstName', this.accountData.keycloakFirstName);
      this.$set(this.formData, 'lastName', this.accountData.keycloakLastName);
      this.$set(this.formData, 'username', this.accountData.keycloakEmail);
    },

    setSmsFormCreation(value) {
      if (value) this.formData.smsOnCreation = 'yes';
      else this.formData.smsOnCreation = null;
    }
  }
};
</script>