import { intakeFormDataKeys } from '../views/IntakeFormPage/data/instake-form-data-keys';
import { vaccineRegistrationDataKeys } from '../views/AccountPage/VaccineInfoBlock/data/vaccine-registration-data-keys.js';

export default () => ({
  localhost: "http://localhost:8080",
  //baseUrlApi: 'https://api.dvgapp.org',
  baseUrlApi: process.env.NODE_ENV === 'production' ? 'https://api.dvgapp.org' : 'https://api.dvgapp.dev',
  baseUrlHohApi: process.env.NODE_ENV === 'production' ? 'https://api.labhoh.app' : 'https://api-test.labhoh.app',
  baseUrlHohSearchEngine:
    process.env.NODE_ENV === 'production'
      ? 'https://dvg-search-prd.t0qg3mdkloj92.us-east-1.cs.amazonlightsail.com/search/webresources'
      : 'https://dvg-search-dev.t0qg3mdkloj92.us-east-1.cs.amazonlightsail.com/search/webresources',
  //baseUrlHohSearchEngine: 'https://dvg-search-prd.t0qg3mdkloj92.us-east-1.cs.amazonlightsail.com/search/webresources',
  baseUrlApiKC: 'https://api.dvgapp.org',
  baseUrlContentApi: 'https://tourist.dvgapp.org',
  keycloak: {
    token: null,
    refreshToken: null,
    kc: null
  },
  logoutUrl: null,
  userObject: null,
  hasRealmRoleFn: null,
  hasRoleTechLab: null,
  //showBackButton: false,
  refreshDialog: false,
  isTokenExpiredFn: null,
  loader: {
    show: false,
    text: null
  },
  config: {
    appVersion: process.env.VUE_APP_TAG_VERSION ? process.env.VUE_APP_TAG_VERSION : 'dev',
    env: process.env.NODE_ENV
  },
  cases: [],
  quarantineSubjectLocalFilterTerms: [], //fix filter, put in account store
  caseAssignedWorkers: [],
  newlyTransferedCaseId: null,
  subjectSelectedCaseTab: {
    caseName: null, //TODO: remove, is used for back button patiencase page
    index: 0
  },
  subjectSelectedCaseHistory: null,
  loadingCases: false,
  selectedCaseNotes: [],
  selectedCaseTestKitData: [],
  notesPageSize: null,
  snackbar: {
    open: false,
    text: null,
    showCloseButton: true,
    timeout: null,
    color: 'info',
    position: 'top',
    customButton: null
  },
  storedVistorSubjects: [],
  visitorApprovalPage: {
    visitors: {
      data: []
    }
  },
  intakeFormData: {
    formDataPrestine: new intakeFormDataKeys(),
    formData: new intakeFormDataKeys(),
    currentStep: 1,
    caseId: null
  },
  privateEntryFormData: {
    entries: []
  },
  hohEntryFormData: {
    selectedMonth: null, //not used yet
    entriesOnDay: []
  },
  doctorOptions: [],
  vaccine: {
    vaccineDataPatientCase: null,
    locations: [],
    inventoryAtLocation: null,
    vaccineRegistrationDialog: {
      vaccine1Active: false,
      vaccine2Active: false,
      vaccine3Active: false,
      vaccine4Active: false,
      vaccine5Active: false,
      vaccine6Active: false,
      vaccine7Active: false,
      vaccine8Active: false,
      form1Valid: false,
      form2Valid: false,
      form3Valid: false,
      form4Valid: false,
      form5Valid: false,
      form6Valid: false,
      form7Valid: false,
      form8Valid: false,
      form1Data: new vaccineRegistrationDataKeys(),
      form2Data: new vaccineRegistrationDataKeys(),
      form3Data: new vaccineRegistrationDataKeys(),
      form4Data: new vaccineRegistrationDataKeys(),
      form5Data: new vaccineRegistrationDataKeys(),
      form6Data: new vaccineRegistrationDataKeys(),
      form7Data: new vaccineRegistrationDataKeys(),
      form8Data: new vaccineRegistrationDataKeys()
    },
    minimumVaccinationAge: 12
  },
  vaccinePage: {
    vaccineEntries: [],
    locations: [],
    vaccineEntriesSize: 0,
    selectedNurse: null,
    savedNurses: [],
    selectedLocation: null,
    selectedCheckinLocation: null,
    manufacturers: []
  },
  vaccineRegistrationPage: {
    registrations: []
  },
  labHohData: {
    testTypes: []
  },
  travelData: {
    flights: []
  }
});
