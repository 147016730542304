import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Keycloak from 'keycloak-js';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { removeVuetifyCalendarError, setKeycloakTokens } from '@/utils/functions.js';

//printing
import VueHtmlToPaper from 'vue-html-to-paper';
Vue.use(VueHtmlToPaper, optionsVueHtmlToPaper);
//barcode scanner
import VueZxingScanner from 'vue-zxing-scanner';
Vue.use(VueZxingScanner);
//qr scanner
import VueQrcodeReader from 'vue-qrcode-reader';
Vue.use(VueQrcodeReader);
//custom tooltip
import VTooltipPlugin from 'v-tooltip';
Vue.use(VTooltipPlugin);
import 'v-tooltip/dist/v-tooltip.css';

require('@/store/subscriber');

Vue.config.productionTip = false;
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

const optionsVueHtmlToPaper = {
  name: '_blank',
  styles: [
    //'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    //'http://testing.triangon.com/test.css' //used to develop
    'https://www.dvgapp.org/custom-print-styles.css'
  ]
};

let initOptions = {
  url: 'https://api.dvgapp.org/auth/',
  realm: 'DVG',
  clientId: 'healthcheck-html5-client',
  onLoad: 'login-required',
  scope: 'offline_access'
};

const keycloak = Keycloak(initOptions);
const token = localStorage.getItem('dashboard-dvg-kc-token');
const refreshToken = localStorage.getItem('dashboard-dvg-kc-refresh-token');

if (token === 'undefined' || refreshToken === 'undefined') {
  localStorage.removeItem('dashboard-dvg-kc-token');
  localStorage.removeItem('dashboard-dvg-kc-refresh-token');
  window.location.reload();
} else {
  keycloak
    .init({
      onLoad: initOptions.onLoad,
      //onLoad: 'check-sso', //offline token
      checkLoginIframe: false,
      token: token, //offline_token
      refreshToken: refreshToken, //offline_token
      pkceMethod: 'S256'
    })
    .then((auth) => {
      if (!auth) {
        //keycloak.login({ scope: 'offline_access' }); //offline_token
        keycloak.login();
      } else {
        console.log('Authenticated');
      }

      new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App)
      }).$mount('#app');

      //vuetify calendar bug error display fix
      removeVuetifyCalendarError();

      setKeycloakTokens(keycloak);
      store.commit('SET_KC_EXPIRED_TOKEN_FN', keycloak.isTokenExpired);
      store.commit('SET_LOGOUT_URL', keycloak.createLogoutUrl());
      store.commit('SET_KEYCLOAK_OBJECT', keycloak);

      keycloak.loadUserInfo().then((data) => {
        if (keycloak.realmAccess && keycloak.realmAccess.roles && keycloak.realmAccess.roles.length > 0) data.roles = keycloak.realmAccess.roles;
        else data.roles = [];

        store.commit('SET_USER_OBJECT', data);
      });

      console.log(
        'Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds'
      );

      setInterval(() => {
        keycloak
          .updateToken(50)
          .then((refreshed) => {
            if (refreshed) {
              console.log('Token refreshed ' + refreshed);
              setKeycloakTokens(keycloak);
            } else {
              console.log(
                'Token not refreshed, valid for ' +
                  Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
                  ' seconds'
              );
            }
          })
          .catch((e) => {
            console.log(e);
            console.log('Failed to refresh token');
            window.location.reload();
          });
      }, 60000);
    })
    .catch((e) => {
      console.log(e);
    });
}
