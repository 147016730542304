import axios from 'axios';
import store from '@/store';
import moment from 'moment';
const FileType = require('file-type');

//let tokenExpired = false;

// axios.interceptors.request.use(config =>{
//     tokenExpired = store.state.isTokenExpiredFn()
//     if(tokenExpired)
//         store.commit('SET_REFRESH_DIALOG', true );
//     else
//         return config;
// }, error => {
//     if(!tokenExpired)
//         return Promise.reject(error);
// });

// axios.interceptors.response.use(response =>{
//     console.log(response);
//     return response;
// }, error => {
//     if(!tokenExpired)
//         return Promise.reject(error);
// });

//var CancelToken;
//var source;

export default {
  //quarantie dashboard

  async getCases({ state, commit }) {
    if (!store.state.userObject || !store.state.userObject.sub) return false;
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/patientCase/caseworker/${store.state.userObject.sub}`)
      .then(res => {
        if (res.status == 200) commit('SET_CASES', res.data);
        return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error Loading Cases',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async getCaseAssignedWorker({ state, commit }) {
    const axiosKC = axios.create();
    delete axiosKC.defaults.headers.common['X-VERSION'];
    return await axiosKC
      .get(`${state.baseUrlApiKC}/auth/admin/realms/DVG/roles/admin/users`) // keycloak url
      .then(res => {
        if (res.status == 200) commit('SET_CASE_ASSIGNED_WORKERS', res.data);
        return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error loading case assigned worker',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async getAccountByEmail({ state, commit }, email) {
    const axiosKC = axios.create();
    delete axiosKC.defaults.headers.common['X-VERSION'];
    return await axiosKC
      .get(`${state.baseUrlApiKC}/auth/admin/realms/DVG/users?email=${email}`)
      .then(res => {
        if (res.status == 200) return res.data;
      })
      .catch(error => {
        console.log(error);
        commit('SET_SNACKBAR', { open: true, text: 'Error loading account by email', color: 'error' });
      });
  },

  async createNote({ state, commit }, payload) {
    commit('SET_SHOW_LOADER', {
      action: true,
      text: 'Creating new note'
    });
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/note/admin/${payload.caseId}`, payload.formData)
      .then(res => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        if (res.status == 200) {
          let payload = {
            open: true,
            text: 'Note successfully added.',
            color: 'success'
          };
          commit('SET_SNACKBAR', payload);
          return res.data;
        } else {
          let payload = {
            open: true,
            text: 'Note not added, something went wrong.',
            color: 'error'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        let payload = {
          open: true,
          text: 'Note not added, something went wrong.',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        console.error(error.response);
        return error;
      });
  },

  async getNotes({ state, commit }, params) {
    commit('SET_SELECTED_CASE_NOTES', []);
    if (params) if (!params.pageNumber) params.pageNumber = 1;
    if (!params.pageSize) params.pageSize = 10;
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/note/admin/${params.caseId}?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`)
      .then(res => {
        if (res.status == 200) commit('SET_SELECTED_CASE_NOTES', res.data);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.log(error);
          let payload = {
            open: true,
            text: 'Error Loading Notes',
            color: 'error'
          };
          commit('SET_SNACKBAR', payload);
        }
      });
  },

  async getNotesPageSize({ state, commit }, params) {
    if (params) if (!params.pageSize) params.pageSize = 10;
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/note/admin/${params.caseId}/pageSize?pageSize=${params.pageSize}`)
      .then(res => {
        if (res.status == 200 && res.data.size) {
          commit('SET_NOTES_PAGE_SIZE', res.data.size);
          return res.data.size;
        }
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error notes page size',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async createUser({ state, commit }, payload) {
    commit('SET_SHOW_LOADER', {
      action: true,
      text: 'Creating new user'
    });
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/user`, payload)
      .then(res => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        if (res.status == 200 && !res.data.message) {
          let payload = {
            open: true,
            text: 'User successfully created.',
            color: 'success'
          };
          commit('SET_SNACKBAR', payload);
          return res.data;
        } else if (res.data.message) {
          let payload = {
            open: true,
            text: res.data.message,
            color: 'error'
          };
          commit('SET_SNACKBAR', payload);
          return false;
        } else {
          let payload = {
            open: true,
            text: 'User not added, something went wrong.',
            color: 'error'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        let payload = {
          open: true,
          text: 'User not added, something went wrong.',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        console.error(error.response);
        return error;
      });
  },

  async editUser({ state, commit }, payload) {
    commit('SET_SHOW_LOADER', {
      action: true,
      text: 'Saving...'
    });
    const axiosKC = axios.create();
    delete axiosKC.defaults.headers.common['X-VERSION'];
    return await axiosKC
      .put(`${state.baseUrlApiKC}/auth/admin/realms/DVG/users/${payload.id}`, payload)
      .then(res => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        if (res.status == 200 || res.status == 204) {
          let payload = {
            open: true,
            text: 'User edited successfully updated.',
            color: 'success'
          };
          commit('SET_SNACKBAR', payload);
          return res;
        }
      })
      .catch(error => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        let payload = {
          open: true,
          text: error.response.data.errorMessage ? error.response.data.errorMessage : 'User not added, something went wrong',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        console.error(error.response);
      });
  },

  async resendUserCredentials({ state, commit }, payload) {
    commit('SET_SHOW_LOADER', {
      action: true,
      text: 'Processing...'
    });
    const axiosKC = axios.create();
    delete axiosKC.defaults.headers.common['X-VERSION'];
    return await axiosKC
      // .put(`${state.baseUrlApi}/healthcheck-restful-api/user/${payload.id}`, payload)
      .put(`${state.baseUrlApiKC}/auth/admin/realms/DVG/users/${payload.id}/execute-actions-email`, payload.action)
      .then(res => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        if (res.status == 200 || res.status == 204) {
          let payload = {
            open: true,
            text: 'Password has been reset and user has been notified.',
            color: 'success'
          };
          commit('SET_SNACKBAR', payload);
          return res;
        }
      })
      .catch(error => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        let payload = {
          open: true,
          text: error.response.data.errorMessage ? error.response.data.errorMessage : 'User not added, something went wrong',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        console.error(error.response);
      });
  },

  async searchCase({ state }, searchObj) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/patientCase/search`, searchObj)
      .then(res => {
        if (res.status == 200) {
          return res.data;
        } else return [];
      })
      .catch(error => {
        console.error(error);
      });
  },

  async getStateHistory({ state, commit }, caseId) {
    commit('SET_STATE_HISTORY_SELECTED_CASE', null);
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/state-history?caseId=${caseId}`)
      //return await axios.get(`https://demo4178399.mockable.io/huisnummer`)
      .then(res => {
        if (res.status == 200) commit('SET_STATE_HISTORY_SELECTED_CASE', res.data);
        return res.data;
      })
      .catch(error => {
        commit('SET_STATE_HISTORY_SELECTED_CASE', []);
        console.log(error);
        let payload = {
          open: true,
          text: 'Error Loading state history',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async transferPatientCase({ state, commit }, payload) {
    return await axios
      .put(`${state.baseUrlApi}/healthcheck-restful-api/patientcase/unit/transfer/${payload.accountId}`, payload.bodyToPost)
      .then(res => {
        if (res.status == 200 && !res.data.message) {
          return res.data;
        } else if (res.data.message) {
          commit('SET_SNACKBAR', { open: true, text: res.data.message, color: 'error' });
          return false;
        } else {
          commit('SET_SNACKBAR', { open: true, text: 'User not added, something went wrong.', color: 'error' });
        }
      })
      .catch(error => {
        commit('SET_SNACKBAR', { open: true, text: 'User not added, something went wrong.', color: 'error' });
        console.error(error.response);
        return error;
      });
  },

  //teskit
  async getPatientTestKitInfo({ state, commit, dispatch }, caseId) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/patienttestkit/${caseId}`)
      .then(res => {
        if (res.status == 200) commit('SET_SUBJECT_CASE_TESTKIT_INFO', res.data);
        return res.data;
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error loading test kit info');
      });
  },

  async updatePatientTestKitInfo({ state, dispatch }, payload) {
    return await axios
      .put(`${state.baseUrlApi}/healthcheck-restful-api/patienttestkit/${payload.id}`, payload)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error saving payment type test kit');
      });
  },

  async downloadTestkitCertificate({ state, dispatch }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/patienttestkit/${payload.caseId}/${payload.teskKitNumber}`, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        if (res.status == 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'TESTKIT-CERTIFICATE-' + payload.teskKitNumber + '.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
          return true;
        } else {
          dispatch('showError', 'Error downloading covid test certificate');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error downloading covid test certificate');
      });
  },

  async deleteVaccineDosage({ state, commit }, id) {
    return await axios
      .delete(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/dose/${id}`)
      .then(res => {
        return res.status == 200 || res.status == 204;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error deleting dosage',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async deleteTestkit({ state, commit }, id) {
    return await axios
      .delete(`${state.baseUrlApi}/healthcheck-restful-api/patienttestkit/${id}`)
      .then(res => {
        if (res.status == 200 || res.status == 204) {
          return true;
        }
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error deleting test kit',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  //visitors actions
  async getVisitorSubjectData({ state, commit }, caseId) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/patientcase/qrcode/${caseId}`)
      .then(res => {
        if (res.status == 200) return res.data;
        else {
          let payload = {
            open: true,
            text: res.data.errorMessage ? res.data.errorMessage : 'Error loading data from subject',
            color: 'info'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: error.response && error.response.data.errorMessage ? error.response.data.errorMessage : 'Error loading data from subject',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async getTestTubeData({ state, commit }, barcode) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/patienttestkit/barcode/${barcode}`)
      //console.log(state, caseId)
      //return await axios.get(`http://demo4178399.mockable.io/huisnummer`)
      .then(res => {
        if (res.status == 202) return 'NOT_FOUND';
        else if (res.status == 200) return res.data;
        else {
          let payload = {
            open: true,
            text: res.data.errorMessage ? res.data.errorMessage : 'Error loading data from subject',
            color: 'info'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        let payload = {
          open: true,
          text: error.response.data.errorMessage ? error.response.data.errorMessage : 'Error loading data from subject',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async linkSubjectToTestTube({ state, commit }, payload) {
    commit('SET_SHOW_LOADER', {
      action: true,
      text: 'One moment please...'
    });
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/patientcase/qrcode/${payload.caseId}`, payload.body)
      .then(res => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        if (res.status == 200) {
          let payload = {
            open: true,
            text: 'Subject linked to test tube',
            color: 'success'
          };
          commit('SET_SNACKBAR', payload);
          return res.data;
        } else {
          let payload = {
            open: true,
            text: res.data.errorMessage ? res.data.errorMessage : 'Subject not linked, something went wrong.',
            color: 'error'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        let payload = {
          open: true,
          text: 'Subject not linked, something went wrong.',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        console.error(error.response);
      });
  },

  async createNewTravelerSubjectPaper({ state, commit }, payload) {
    return await axios
      .put(`${state.baseUrlApi}/healthcheck-restful-api/patientcase/paper/register`, payload)
      .then(res => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        if (res.status == 200) {
          return res.data;
        } else {
          let payload = {
            open: true,
            text: res.data.errorMessage ? res.data.errorMessage : 'Error loading data from subject',
            color: 'error'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        let payload = {
          open: true,
          text: error?.response?.data?.errorMessage ? error.response.data.errorMessage : 'Error loading data from subject',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        console.error(error);
      });
  },

  async createNewLocalSubjectPaper({ state, commit }, payload) {
    return await axios
      .put(`${state.baseUrlApi}/healthcheck-restful-api/patientcase/paper/register/Local`, payload)
      .then(res => {
        if (res.status == 200) {
          return res.data;
        } else {
          let payload = {
            open: true,
            text: res.data.errorMessage ? res.data.errorMessage : 'Error loading data from subject',
            color: 'error'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        let payload = {
          open: true,
          text: error.response.data.errorMessage ? error.response.data.errorMessage : 'Error loading data from subject',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        console.error(error.response.data.errorMessage);
      });
  },

  async searchAccomodations({ state }, searchAccomodation) {
    return await axios
      .get(`${state.baseUrlContentApi}/street-names?streetName_contains=${searchAccomodation}`, {
        headers: null
      })
      .then(res => {
        if (res.status == 200) {
          return res.data;
        } else return [];
      })
      .catch(error => {
        console.error(error);
      });
  },

  async getVisitorsForApproval({ state, commit }, payload) {
    return await axios
      .get(
        `${state.baseUrlApi}/healthcheck-restful-api/passengercard?index=${payload.index}&pageSize=${payload.pageSize}${
          payload.filters ? payload.filters : ''
        }`
      )
      .then(res => {
        if (res.status == 200) commit('SET_VISITORS_FOR_APPROVAL', res.data);
        return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error Loading Visitors',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async getVisitorsForApprovalImage({ state, commit }, id) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/passengercard/test/image/${id}`, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        if (res.status == 200) {
          return (async () => {
            const fileExtension = await FileType.fromBuffer(res.data);
            return {
              ext: fileExtension.ext,
              file: Buffer.from(res.data, 'binary').toString('base64'),
              id: id
            };
          })();
        }
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error Loading Visitor Image',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async downloadVisitorApprovalFile({ state, commit }, id) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/passengercard/test/image/${id}`, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        if (res.status == 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'some_file_name.docx');
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error Loading Visitor Image',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async visitorApprovalAction({ state, commit }, payload) {
    commit('SET_SHOW_LOADER', {
      action: true,
      text: 'One moment please...'
    });
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/passengercard`, payload.body)
      .then(res => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        if (res.status == 200) {
          commit('SET_SNACKBAR', {
            open: false
          });
          let snackbarObject = {
            open: true,
            text: payload.actionText,
            color: 'success'
          };
          commit('SET_SNACKBAR', snackbarObject);
          return res.data;
        } else {
          let payload = {
            open: true,
            text: 'Visitor approval action not taken, something went wrong',
            color: 'error'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
        let payload = {
          open: true,
          text: 'Visitor approval action not taken, something went wrong',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        console.error(error.response);
        return error;
      });
  },

  async searchTravelers({ state, commit }, body) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/passengercard/search`, body)
      .then(res => {
        if (res.status == 200) return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error Loading Travelers',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async searchSubjectPaymentInfo({ state, commit }, body) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/testkitpayment?maxSize=5`, body)
      .then(res => {
        if (res.status == 200) return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error Loading payment data',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async getDoctorOptions({ state, commit }) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/hoh/doctorlist`)
      .then(res => {
        if (res.status == 200) {
          commit('SET_DOCTOR_OPTIONS', res.data);
          return res.data;
        }
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error Loading doctor options',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async getTestTypes({ state, commit }) {
    const axiosLab = axios.create();
    delete axiosLab.defaults.headers.common['X-VERSION'];
    return await axiosLab
      .get(`${state.baseUrlHohApi}/labhoh-restful-api/testtype`)
      .then(res => {
        if (res.status == 200) {
          commit('SET_TEST_TYPES', res.data);
          return res.data;
        }
      })
      .catch(error => {
        console.log(error);
        commit('SET_SNACKBAR', { open: true, text: 'Error Loading test types data', color: 'error' });
      });
  },

  //intake form
  async getIntakeFormData({ state, commit }, caseId) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/intake/${caseId}`)
      .then(res => {
        if (res.status == 200) return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error getting intake form data',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        return false;
      });
  },

  async submitIntakeForm({ state, commit }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/intake/${payload.caseId}`, payload.formData)
      .then(res => {
        if (res.status == 200) return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error submitting intake form data',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        return false;
      });
  },

  //private entry form
  async getPrivateEntryFormEntries({ state, commit }, payload) {
    //pageNumber, pageSize, sort
    return await axios
      .get(
        `${state.baseUrlApi}/healthcheck-restful-api/privateclinictest?pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&sort=${payload.sort}`
      )
      .then(res => {
        if (res.status == 200) {
          commit('SET_PRIVATE_ENTRY_FORM_ENTRIES', res.data);
          return res.data;
        } else {
          let payload = {
            open: true,
            text: res.data.errorMessage ? res.data.errorMessage : 'Error loading private entry form entries',
            color: 'info'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: error.response && error.response.data.errorMessage ? error.response.data.errorMessage : 'Error loading private entry form entries',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async submitPrivateEntryForm({ state, commit }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/privateclinictest`, payload)
      .then(res => {
        if (res.status == 200) return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error submitting private entry form data',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        return false;
      });
  },

  //HOH form
  async getHohEntryFormsAppointments({ state, commit }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/hoh/localtestform/${payload.minDate}/${payload.maxDate}`)
      .then(res => {
        if (res.status == 200) {
          commit('SET_HOH_ENTRY_FORM_ENTRIES', res.data);
          return res.data;
        } else {
          let payload = {
            open: true,
            text: res.data.errorMessage ? res.data.errorMessage : 'Error loading private entry form entries',
            color: 'info'
          };
          commit('SET_SNACKBAR', payload);
        }
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: error.response && error.response.data.errorMessage ? error.response.data.errorMessage : 'Error loading private entry form entries',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },
  async submitHohEntryForm({ state, commit }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/hoh/localtestform`, payload)
      .then(res => {
        if (res.status == 200) return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error submitting HOH entry form data',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        return false;
      });
  },

  //vaccines
  async getVaccinePatientCase({ state, dispatch, commit }, patientCaseId) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/${patientCaseId}`)
      .then(res => {
        if (res.status == 200) {
          commit('SET_VACCINE_DATA_PATIENT_CASE', res.data);
          return res.data;
        } else if (res.status == 204) {
          commit('SET_VACCINE_DATA_PATIENT_CASE', null);
          return false;
        } else {
          commit('SET_VACCINE_DATA_PATIENT_CASE', null);
          dispatch('showError', 'Error getting vaccine data');
          return false;
        }
      })
      .catch(error => {
        console.log(error);
        commit('SET_VACCINE_DATA_PATIENT_CASE', null);
        dispatch('showError', 'Error getting vaccine data');
        return false;
      });
  },

  async getVaccineLocations({ state, commit, dispatch }) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/location`)
      .then(res => {
        if (res.status == 200) {
          commit('SET_VACCINE_LOCATIONS', res.data);
          return res.data;
        } else {
          dispatch('showError', 'Error loading vaccine locations');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error loading vaccine locations');
      });
  },

  async getVaccineInventoryByLocation({ state, commit, dispatch }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/inventory/location/${payload}`)
      .then(res => {
        if (res.status == 200) {
          commit('SET_VACCINE_INVENTORY_LOCATION', res.data);
          return res.data;
        } else {
          dispatch('showError', 'Error loading vaccine inventory');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error loading vaccine inventory');
      });
  },

  async getCheckInDataUser({ state, dispatch }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/checkin/${payload}`)
      .then(res => {
        if (res.status == 200) {
          return res.data;
        } else {
          dispatch('showError', 'Error loading check-in data for user');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error loading check-in data for user');
      });
  },

  async doseUser({ state, dispatch }, payload) {
    return await axios
      .put(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/inventory/${payload.inventoryId}/dose`, payload.bodyToSubmit)
      .then(res => {
        if (res.status == 200) {
          return res.data;
        } else {
          dispatch('showError', res.errorMessage ? res.errorMessage : 'Error saving data for user');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', error.response.data.errorMessage ? error.response.data.errorMessage : 'Error saving data for user');
      });
  },

  async submitVaccineEntryForm({ state, commit }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/inventory`, payload)
      .then(res => {
        if (res.status == 204) return true;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error submitting vaccine entry form data',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        return false;
      });
  },

  async getVaccineEntries({ state, commit }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/inventory?${payload}`)
      .then(res => {
        if (res.status == 200) {
          return res.data;
        }
      })
      .catch(error => {
        let payload = {
          open: true,
          text: error.response.data.errorMessage ? error.response.data.errorMessage : 'Error getting vaccine entries',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async transferVaccineVials({ state, dispatch }, payload) {
    const params = {
      location: {
        id: payload.location
      },
      amountOfVials: payload.amountOfVials,
      note: payload.note
    };

    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/inventory/${payload.id}/transfer`, params)
      .then(res => {
        if (res.status == 200 || res.status == 204) return true;
        else dispatch('showError', 'Error transfering vaccine batch');
      })
      .catch(error => {
        dispatch('showError', error.response.data.errorMessage ? error.response.data.errorMessage : 'Error transfering vaccine batch');
        return false;
      });
  },

  async transferVaccineDosage({ state, dispatch }, payload) {
    const params = {
      location: {
        id: payload.location
      },
      totalTransferDosage: payload.totalTransferDosage,
      note: payload.note
    };

    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/inventory/${payload.id}/transfer`, params)
      .then(res => {
        if (res.status == 200 || res.status == 204) return true;
        else dispatch('showError', 'Error transfering vaccine dosage');
      })
      .catch(error => {
        dispatch('showError', error.response.data.errorMessage ? error.response.data.errorMessage : 'Error transfering vaccine dosage');
        return false;
      });
  },

  async transferVaccineBox({ state, commit }, payload) {
    const params = {
      location: {
        id: payload.location
      },
      allOfBox: payload.allOfBox,
      note: payload.note
    };

    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/inventory/${payload.id}/transfer`, params)
      .then(res => {
        if (res.status == 200) return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error transfering vaccine batch',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        return false;
      });
  },

  async confirmReceivedVaccine({ state, commit }, payload) {
    const params = {
      amountOfVials: parseInt(payload.amountOfVials)
    };
    return await axios
      .put(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/inventory/${payload.id}/transfer`, params)
      .then(res => {
        if (res.status == 200) return res.data;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error confirm receiving vaccines',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        return false;
      });
  },

  async createVaccineAppointment({ state, commit }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/vaccine`, payload)
      .then(res => {
        if (res.status == 204 || res.status == 200) return true;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error creating vaccine appointment',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        return false;
      });
  },

  async updateVaccineAppointment({ state, commit }, payload) {
    return await axios
      .put(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/${payload.id}`, payload)
      .then(res => {
        if (res.status == 204) return true;
      })
      .catch(error => {
        console.log(error);
        let payload = {
          open: true,
          text: 'Error creating vaccine appointment',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
        return false;
      });
  },

  async checkInUserVaccine({ state, commit }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/checkin`, payload)
      .then(res => {
        if (res.status == 200 || res.status == 204) return true;
      })
      .catch(error => {
        let payload = {
          open: true,
          text: error.response.data.errorMessage ? error.response.data.errorMessage : 'Error checking user in',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async getVaccineManufacturers({ state, commit }) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/manufacture`)
      .then(res => {
        if (res.status == 200) {
          commit('SET_VACCINE_MANUFACTURES', res.data);
          return res.data;
        }
      })
      .catch(error => {
        let payload = {
          open: true,
          text: error.response.data.errorMessage ? error.response.data.errorMessage : 'Error checking user in',
          color: 'error'
        };
        commit('SET_SNACKBAR', payload);
      });
  },

  async REQUEST_VACCINE({ state, dispatch }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/request`, payload)
      .then(res => {
        if (res.status == 204 || res.status == 200) {
          return true;
        } else {
          dispatch('showError', 'Error submitting constent data');
          return false;
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error submitting consent data');
        return false;
      });
  },

  async GET_VACCINE_REQUEST({ state, dispatch }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/request/${payload}`)
      .then(res => {
        if (res.status == 204 || res.status == 200) {
          return res.data;
        } else {
          dispatch('showError', 'Error getting vaccine request data');
          return false;
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error getting vaccine request data');
        return false;
      });
  },

  async GET_VACCINE_REQUEST_HISTORIC({ state, dispatch }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/request/historic/${payload}`)
      .then(res => {
        if (res.status == 204 || res.status == 200) {
          return res.data;
        } else {
          dispatch('showError', 'Error getting vaccine request data');
          return false;
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error getting vaccine request data');
        return false;
      });
  },

  async CHECK_FOR_VACCINE_CERTIFICATE({ state, dispatch }, patientCaseId) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/verification/check?id=${patientCaseId}`)
      .then(res => {
        if (res.status == 200 || res.status == 204) {
          return res.data;
        } else {
          dispatch('showError', 'Error checking vaccine certificate');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error checking vaccine certificate');
      });
  },

  async downloadVaccineCertificate({ state, dispatch }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/verification/certification`, payload, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        if (res.status == 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'TESTKIT-CERTIFICATE-' + payload.vaccineId + '.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
          return true;
        } else {
          dispatch('showError', 'Error downloading covid test certificate');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error downloading covid test certificate');
      });
  },

  async REGISTER_GHOST_VACCINATION({ state, dispatch }, payload) {
    return await axios
    .post(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/manualEntry`, payload)
    .then(res => {
        if (res.status == 200 || res.status == 204) {
          return true;
        } else {
          dispatch('showError', 'Error registering vaccine');
          return false;
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error registering vaccine');
      });
  },

  //generic
  async getSubjectDataAzvNumber({ state, dispatch }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/admin/patientCase/azv/${payload}`)
      .then(res => {
        if (res.status == 200) {
          // res.data = res.data.map(item => {
          //   return { ...item, azvNumber: item.azvCardNumber };
          // });
          return res.data;
        } else {
          dispatch('showError', 'Error loading account from AZV number');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error loading account from AZV number');
      });
  },

  async getPatientCase({ state, dispatch }, patientCaseId) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/patientCase/${patientCaseId}`)
      .then(res => {
        if (res.status == 200 || res.status == 204) {
          //translate keys for display
          if (!res.data.patientCaseId && res.data.id) res.data.patientCaseId = res.data.id;
          if (!res.data.azvNumber && res.data.azvCardNumber) res.data.azvNumber = res.data.azvCardNumber;
          if (!res.data.birthDay && res.data.birthDate) res.data.birthDay = res.data.birthDate;
          return res.data;
        } else {
          dispatch('showError', 'Error loading patient case data');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error loading patient case data');
      });
  },

  async deletePatientCase({ state, dispatch }, payload) {
    return await axios
      .delete(`${state.baseUrlApi}/healthcheck-restful-api/patientCase/${payload}`)
      .then(res => {
        if (res.status == 200 || res.status == 200) {
          console.log('success');
        } else {
          dispatch('showError', 'Error deleting patient case');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error deleting patient case');
      });
  },

  showError({ commit }, text) {
    let payload = {
      open: true,
      text: text,
      color: 'error'
    };
    commit('SET_SNACKBAR', payload);
  },

  // travel info
  async checkEdCardData({ state }, payload) {
    return await axios
      .put(`${state.baseUrlApi}/healthcheck-restful-api/patientcase/unit/${payload.id}`, payload.body)
      .then(res => {
        if (res.status == 200) {
          return res.data;
        }
      })
      .catch(error => {
        console.log(error);
      });
  },

  async createCaseTravelItem({ state, dispatch }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/flight/${payload.id}`, payload.body)
      .then(res => {
        if (res.status == 200) {
          return true;
        } else if (res.status == 202) {
          dispatch('showError', res.data.errorMessage);
        } else {
          dispatch('showError', 'Error creating flight info');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error creating flight info');
      });
  },

  async updateCaseTravelItem({ state, dispatch }, payload) {
    return await axios
      .put(`${state.baseUrlApi}/healthcheck-restful-api/flight/${payload.id}`, payload.body)
      .then(res => {
        if (res.status == 200) {
          return true;
        } else if (res.status == 202) {
          dispatch('showError', res.data.errorMessage);
        } else {
          dispatch('showError', 'Error updating flight info');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error updating flight info');
      });
  },

  async getAllCaseTravelItems({ state, dispatch }, caseId) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/flight/${caseId}`)
      .then(res => {
        if (res.status == 200) {
          return res.data;
        } else if (res.status == 202) {
          dispatch('showError', res.data.errorMessage);
        } else {
          dispatch('showError', 'Error getting travel data');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error getting travel data');
      });
  },

  async verifyEdCardData({ state, dispatch }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/verify/check/${payload}`)
      .then(res => {
        if (res.status == 200) {
          return res.data;
        } else if (res.status == 202) {
          dispatch('showError', res.data.errorMessage);
        } else {
          dispatch('showError', 'Error getting ED card data');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error getting ED card data');
      });
  },

  //DCC info
  async requestDcc({ state, dispatch }, payload) {
    return await axios
      .post(`${state.baseUrlApi}/healthcheck-restful-api/dcc/paper/${payload.caseId}?dataType=${payload.dataType}`)
      .then(res => {
        if (res.status == 200 || res.status == 204) {
          return res.data;
        } else if (res.status == 202) {
          dispatch('showError', res.data.errorMessage);
        } else {
          dispatch('showError', 'Error requesting DCC data');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error requesting DCC data');
      });
  },

  async getDccData({ state, dispatch }, caseId) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/dcc/admin/${caseId}`)
      .then(res => {
        if (res.status == 200 || res.status == 204) {
          return res.data;
        } else if (res.status == 202) {
          dispatch('showError', res.data.errorMessage);
        } else {
          dispatch('showError', 'Error requesting DCC data');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error requesting DCC data');
      });
  },

  async downloadDccCertificate({ state, dispatch }, payload) {
    return await axios
      .get(`${state.baseUrlApi}/healthcheck-restful-api/dcc/paper/${payload.dccId}`, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        if (res.status == 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', 'DCC-CERTIFICATE-' + payload.certId + '_' + moment().format('MMDYYYYhmmss') + '.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
          return true;
        } else {
          dispatch('showError', 'Error requesting DCC certificate');
        }
      })
      .catch(error => {
        console.log(error);
        dispatch('showError', 'Error requesting DCC certificate');
      });
  },

  async suspendVaccineDosage({state, dispatch}, payload) {
    await axios.put(`${state.baseUrlApi}/healthcheck-restful-api/vaccine/suspend/${payload.id}`, { state: payload.state })
    .then((result) => {
      if(result.status !== 200 && result.status !== 204) throw new Error("Vaccine (un)suspension failed.");
    })
    .catch((err) => {
      console.log(err);
      dispatch("showError", "Error setting vaccine dosage state.");
    });
  }
};
